<template>
  <v-container>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-row>
      <v-col cols="12" sm="5">
        <h5>Feldname</h5>
      </v-col>
      <v-col cols="12" sm="5">
        <h5>Typ</h5>
      </v-col>
      <!--
      <v-col cols="12" sm="2" md="2">
        <h5>Bei Neuanlage sichtbar</h5>
      </v-col>
      <v-col cols="12" sm="2" md="2">
        <h5>Pflichtfeld</h5>
      </v-col>-->
    </v-row>

    <v-row v-for="customField of customFields" :key="customField.id">
      <v-col cols="12" sm="5">
        {{ customField.name }}
      </v-col>
      <v-col cols="12" sm="5">
        <v-icon>{{ getIconAndTypeName(customField.type, true) }}</v-icon>
        {{ getIconAndTypeName(customField.type, false) }}
      </v-col>
      <!--
            <v-col cols="12" sm="2" md="2">
              <v-icon v-if="customField.showOnCreate">mdi-check</v-icon>
              <v-icon v-if="!customField.showOnCreate">mdi-cancel</v-icon>
            </v-col>

            <v-col cols="12" sm="2">
              <v-icon v-if="!customField.isRequired">mdi-cancel</v-icon>
              <v-icon v-if="customField.isRequired">mdi-check</v-icon>
            </v-col>
        -->
      <v-col cols="12" sm="2">
        <v-tooltip right color="red" max-width="200px">
          <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon @click="sureToDeleteDialog=true">mdi-delete</v-icon>
                      </span>
          </template>
          ACHTUNG durch Löschen des Feldes werden alle bereits hinterlegtem Felder aus den Datensätzen gelöscht.
        </v-tooltip>
      </v-col>
      <SureToDelete
          :dialog="sureToDeleteDialog"
          :text="'Bist du Sicher, dass du das Feld unwiderruflich löschen möchtest? Hierdurch werden ALLE Werte gelöscht, die in dieses Feld geschrieben wurden.'"
          @cancel="sureToDeleteDialog = false"
          @sure="deleteCustomField(customField.id); sureToDeleteDialog = false"
      ></SureToDelete>
    </v-row>

    <v-row>
      <v-col cols="12" sm="5">
        <v-text-field
            label="Bezeichnung"
            v-model="newCustomField.name"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="5">
        <Autocomplete
            v-model="newCustomField.type"
            :data="getTypeData()"
            @sendData="setType"
        >
        </Autocomplete>
      </v-col>


      <!--
      <v-col cols="12" sm="2" md="2">
        <v-checkbox
            :value="newCustomField.showOnCreate"
            v-model="newCustomField.showOnCreate"
            v-if="!newCustomField.isRequired"
        >
        </v-checkbox>
        <v-checkbox
            value="true"
            v-model="newCustomField.showOnCreate"
            v-if="newCustomField.isRequired"
            readonly
        >
        </v-checkbox>
      </v-col>
      <v-col cols="12" sm="2" md="2">
        <v-checkbox
            v-model="newCustomField.isRequired"
        >
        </v-checkbox>
      </v-col>
-->
      <v-col cols="12" sm="2" md="2">
        <v-btn class="mt-3" @click="createCustomField">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import {error, msgObj, success} from "@/helper/helper";

const Autocomplete = () => import("@/components/dialog/fields/AutoComplete");
const SureToDelete = () => import("@/components/generalUI/SureToDelete");
const Message = () => import("@/components/generalUI/Message");

export default {
  name: "CustomFieldEntitySettings",
  computed: {},
  components: {Autocomplete, SureToDelete,Message},
  data() {
    return {
      message: msgObj(),
      newCustomField: {},
      sureToDeleteDialog:false
    }
  },
  props: {
    user: {
      type: Object,
      default() {
        return {}
      }
    },
    customFields: {
      type: Array,
      default() {
        return []
      }
    },
    entity: {
      type: String,
      default() {
        return ''
      }
    }
  },
  mounted() {
  },
  methods: {
    setType(payload) {
      this.newCustomField.type = payload.value
    },
    getTypeData() {
      return {
        items: [
          {text: 'Text', value: 'text'},
          {text: 'Zahl', value: 'number'},
          {text: 'Dezimalzahl', value: 'float'},
          {text: 'Datum', value: 'date'},
          {text: 'Web-Link (URL)', value: 'link'},
        ],
        title: "Typ"
      }
    },
    createCustomField() {
      this.$store.dispatch('settings/getCustomFieldCount', {
        uid: this.user.id,
      }).then((count)=>{
        if((this.user.paymentPlan < 2 && count > 2) || (this.user.paymentPlan === 2 && count > 19)){
          this.message = error('Du hast bereit die maximal Anzahl an individuellen Feldern erstellt. Bitte aktualisiere deinen Plan oder kontaktiere uns.')
        }else{
          this.$store.dispatch('settings/createCustomField', {
            uid: this.user.id,
            entity: this.entity,
            name: this.newCustomField.name,
            type: this.newCustomField.type,
            showOnCreate: false,
            isRequired: false,
            position: 1,
          }).then(() => {
            this.newCustomField = {}
          }).catch((err) => {
            this.message = error(err)
          })
        }
      }).catch((err)=>{
        this.message = error(err)
      })
    },
    deleteCustomField(id) {
      this.$store.dispatch('settings/deleteCustomField', {
        uid: this.user.id,
        id,
      }).then(() => {
        this.message = success('Feld erfolgreich gelöscht.')
        this.$store.dispatch('settings/deleteCustomFieldValues', {
          id
        }).catch((err) => {
          this.message = error(err)
        })
        setTimeout(() => this.message.show = false, 5000);
      }).catch((err) => {
        this.message = error(err)
      })
    },
    getIconAndTypeName(type, getIcon) {
      let icon = ''
      let name = ''
      switch (type) {
        case 'date':
          icon = 'mdi-calendar'
          name = 'Datum'
          break
        case 'time':
          icon = 'mdi-clock'
          name = 'Zeit'
          break
        case 'text':
          icon = 'mdi mdi-ab-testing'
          name = 'Text'
          break
        case 'number':
          icon = 'mdi mdi-numeric-9'
          name = 'Nummer'
          break
        case 'float':
          icon = 'mdi mdi-surround-sound-3-1'
          name = 'Dezimal'
          break
        case 'link':
          icon = 'mdi mdi-link'
          name = 'Link'
          break
      }
      if (getIcon) {
        return icon
      } else {
        return name
      }
    },
  }
}
</script>
